import React from "react";
import "./style.css";
import Brain from "../../../images/ui/brain.webp";
import Hand from "../../../images/ui/hand.webp";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <div className="about-section">
      <section className="about" id="left">
        <Container className="section text-center">
          <Row lg={2} xs={1} className="flex">
            <Col className="img-box">
              <img src={Brain} class="about-img" alt="Brain" />
            </Col>
            <Col className="text-box d-flex align-items-center">
              <div>
                <h2>Who are we?</h2>
                <p>
                Teskill is a leading software company committed to delivering cutting-edge software solutions with top-notch security. Our team of experts harnesses the latest technologies to help businesses achieve their goals efficiently and securely.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about" id="right">
        <Container className="section text-center">
          <Row lg={2} xs={1} className="flex">
            <Col className="text-box d-flex align-items-center">
              <div>
                <h2>Our Goal</h2>
                <p>
                At Teskill, our goal is to empower businesses with software solutions that streamline their operations, enhance their productivity, and ensure the highest levels of security. We strive to be a reliable partner for our clients' success.
                </p>
              </div>
            </Col>
            <Col className="img-box">
              <img src={Hand} class="about-img" alt="Hand" />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
