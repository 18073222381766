const contactData = [
    {
        "title": "Sri Lanka, Southern Province, LK",
        "icon": <i class="fas fa-home me-3"></i>,
    },
    {
        "title": "contact@teskill.com",
        "icon": <i class="fas fa-envelope me-3"></i>
        
    },
    {
        "title": "+ 94 76 56 91 764",
        "icon": <i class="fas fa-phone me-3"></i>,
    },
    {
        "title": "+ 94 76 56 91 764",
        "icon": <i class="fab fa-whatsapp me-3"></i>,
    }
]

export {contactData};