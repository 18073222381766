import React, { useEffect } from 'react'
import './style.css'
import styled from 'styled-components';


const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
const Error404 = () => {

  useEffect(() => {
    document.title = "404 Not Found";
  }, []);

  return (
    <Center>
    <div className="container">
      <div class="row text-center">
        <div class="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main">
          <div class="row">
            <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
              <h1 class="m-0">404</h1>
              <h6>Page not found</h6>
              <p>Lorem ipsum dolor sit <span class="text-info">amet</span>, consectetur <span class="text-info">adipisicing</span> elit, sed do eiusmod.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Center>
  )
}

export default Error404;