import UniqueNameGeneratorImage from "../../../images/portfolio/unique-name-generator.png";
import UptrendyImage from "../../../images/portfolio/uptrendy.png";
import SwaraImage from "../../../images/portfolio/swara-educaation.png";
import MathsGuideImage from "../../../images/portfolio/maths-guide.png";
import OwlEyeImage from "../../../images/portfolio/owl-eye.png";
import {SiPython, SiFlutter, SiReact, SiWordpress, SiWoo, SiElementor, SiFirebase} from "react-icons/si";

const portfolioData = [

    {
        title: "Name Generator",
        image: UniqueNameGeneratorImage,
        description: "Unique Name Generator is an all-in-one online tool that generates unique and creative names for individuals, businesses, or products, along with credit card number generation and validation.",
        url: "https://unique-name-generator.web.app/",
        alt: "Unique Name Generator ",
        platforms: [ <SiReact/>, <SiPython/>]
    },

    {
        title: "Uptrendy.lk",
        image: UptrendyImage,
        description: "Uptrendy.lk is Sri Lanka's premier online clothing store, offering trendy and high-quality apparel, accessories, and footwear for men, women, and children at affordable prices.",
        url: "https://uptrendy.lk/",
        alt: "Uptrendy.lk",
        platforms: [<SiWordpress/>, <SiWoo/>, <SiElementor/>]
    }
    ,
    {
        title: "Swara App",
        image: SwaraImage,
        description: "Swara is an innovative music learning app that offers comprehensive lessons, personalized feedback, and a vast library of songs to help users master their musical skills.",
        url: "https://play.google.com/store/apps/details?id=com.dimaljay.swara",
        alt: "Swara App",
        platforms: [<SiFlutter/>, <SiFirebase/>],
    }
    ,
    {
        title: "Maths Guide",
        image: MathsGuideImage,
        description: "Maths Guide is a reliable and user-friendly maths learning app that offers step-by-step guidance, interactive exercises, and personalized progress tracking to help users improve their math skills.",
        url: "https://play.google.com/store/apps/details?id=com.dimaljay.maths_guide",
        alt: "Maths Guide",
        platforms: [<SiFlutter/>, <SiFirebase/>]
    },
    {
        title: "Owl Eye",
        image: OwlEyeImage,
        description: "Owl Eye is a website information gathering tool that helps users extract valuable data and insights for research and analysis purposes.",
        url: "https://www.geeksforgeeks.org/owl-eye-tool-for-information-gathering-for-domain-name-or-ip-address/",
        alt: "Owl Eye",
        platforms: [<SiPython/> ]
    }
];

export { portfolioData }