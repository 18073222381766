import "./style.css";
import { Hero } from "../../components/Hero";
import { About } from "../../components/pages/About";
import { Services } from "../../components/pages/Services";
import { Portfolio } from "../../components/pages/Portfolio";
import { Footer, Header } from "../../components/Widgets";
import { Contact } from "../../components/pages/Contact";
import Hero2 from "../../components/Hero2/Hero2";

function Home() {
  return (
    <div className="App">
      <Header />
      <Hero2/>
      {/* <Hero  /> */}
      <About />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
