import React from "react";
import "./style.css";
import ServicesList from "../../pages/Services/ServicesList";
import { contactData } from "./FooterData";
import { technologiesData } from "./TechnologiesData";
import { FaGithub,FaTwitter,FaFacebook } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer class="text-center text-lg-start bg-light text-muted">
      <section class="">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <div class="text-uppercase fw-bold mb-4">
                <i class="fas fa-gem me-3"></i>Teskill Technologies
              </div>
              <p>
              Teskill Technologies is a Sri Lankan software company that provides innovative technology solutions and software development services for businesses.
              </p>
              <div className="footer-icons">
                <a href="https://github.com/TesKill-Technologies" target="_blank" className="social-icon"><FaGithub /></a>
                <a href="http://facebook.com/teskilltechnologies" target="_blank" className="social-icon"><FaFacebook /></a>
                <a href="https://twitter.com/TeskillT" target="_blank" className="social-icon"><FaTwitter /></a>
              </div>
            </div>

            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <div class="text-uppercase fw-bold mb-4">Technologies</div>
              
              {technologiesData.map((e) => <p>
                <a href="#!" class="text-reset">
                  {e}
                </a>
              </p>)}
            </div>

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <div class="text-uppercase fw-bold mb-4">Our Services</div>
              {ServicesList.map((e)=> <p>
                <a href="#!" class="text-reset">
                  {e.title}
                </a>
              </p>)}
              
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <div class="text-uppercase fw-bold mb-4">Contact</div>
              {contactData.map((e)=><p>{e.icon} {e.title}</p>)}
            </div>
          </div>
        </div>
      </section>

      <div class="text-center p-4" id="copyright">
        © Copyright 2022 <a class="text-reset fw-bold" href="https://www.teskill.com/"> TesKill</a> All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
