import React from "react";
import { Navbar, Container,  Nav} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import logo from "../../../images/brand/header-logo.svg"

import './style.css';

const HeaderButton = styled.button`
    background-color: transparent;
    border: 1px solid red;
    color:red;
    font-weight: bold;
    border-radius: var(--ts-border-radius);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom:0.5rem;
    :hover{
      color: white;
      background-color: red;
      border: 1px solid red;
    }
    
`

const NavBarItem = styled.div`
    padding: 0.5rem;
    font-weight: 500;
    color: white;
`

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" style={{position:"absolute", width: "100%"}}  variant="dark">
      <Container>
        <Navbar.Brand href="/"><img src={logo} height={40} alt="Logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link href="#"><NavBarItem>Home</NavBarItem></Nav.Link>
            <Nav.Link  href="#services"><NavBarItem>Services</NavBarItem></Nav.Link>
            <Nav.Link  href="#portfolio"><NavBarItem>Portfolio</NavBarItem></Nav.Link>
            {/* <Nav.Link  href="#"><NavBarItem>Team</NavBarItem></Nav.Link> */}
            <Nav.Link href="#contact"><HeaderButton>Contact</HeaderButton></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
