import React from 'react'

function Portfolio() {
  return (
    <div>
      <h1>Portfolio</h1>
    </div>
  )
}

export default Portfolio;
