import React from "react";
import "./style.css";
import { Row, Col, Container, Button } from "react-bootstrap";
import { portfolioData } from "./PortfolioData";
import styled from "styled-components";

const Platforms = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--ts-light);
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const Platform = styled.div`
  padding: calc(var(--ts-padding)*.5);
  padding-bottom: calc(var(--ts-margin));
`;

const PortfolioCard = ({ props }) => {

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Col className="portfolio-card">
      <div className="portfolio-body">
        <h2>{props.title}</h2>
        <img src={props.image} alt={props.alt} class="img-fluid" />
        <p>{props.description}</p>
        <Platforms>
          {props.platforms.map((e) => (
            <Platform>{e}</Platform>
          ))}
        </Platforms>
        <center>
            <a href={props.url} target="_blank" className="btn btn-outline-light portfolio-btn" rel="dofollow">Explore</a>
          
        </center>
      </div>
    </Col>
  );
};

const Portfolio = () => {
  return (
    <section class="portfolio" id="portfolio">
      <h1 className="header">Our Projects</h1>
      <Container class="container">
        <Row xl={4} lg={3} md={2} xs={1}>
          {portfolioData.map((item) => (
            <PortfolioCard props={item} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Portfolio;
