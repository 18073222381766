import React from "react";
import "./style.css";
import ServicesList from "./ServicesList";
import { Col, Container, Row } from "react-bootstrap";

const ServiceBox = ({ title, icon, description }) => {
  return (
    <Col className="service-body">
      <div className="service-box">
      <img src={icon} alt={title} />
      <h2>{title}</h2>
      <p>{description}</p>
      </div>
    </Col>
  );
};

const Services = () => {
  return (
    <section class="services" id="services">
      <h1 className="header">Our Great Services</h1>
      <Container className="text-center">
        <Row lg={3} md={2} xs={1}>
          {ServicesList.map((service)=><ServiceBox title={service.title} icon={service.icon} description={service.description}/>)}
        </Row>
      </Container>
    </section>
  );
};

export default Services;

