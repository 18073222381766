import React from "react";
import "./style.css";
import { Button } from "react-bootstrap";

const Contact = () => {
  return (
    <div className="Contact" id="contact" data-testid="Contact">
      <h2>Contact Us Now</h2>
      <p>Talk to Our Team</p>
      <a href="mailto:contact@teskill.com">
        <Button variant="outline-light" className="rounded-5 px-4 py-2 mt-4">Write a Quote</Button>
      </a>
    </div>
  );
};

export default Contact;
