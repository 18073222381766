import React, { useState, useEffect } from 'react';

const Typewriter = ({text}) => {
  const aText = [
    text,
  ];
  const iSpeed = 100; // time delay of print out
  const iScrollAt = 20; // start scrolling up at this many lines
  const [iIndex, setIndex] = useState(0);
  const [iArrLength, setArrLength] = useState(aText[0].length);
  var [iTextPos, setTextPos] = useState(0);
  const [sContents, setContents] = useState('');

  useEffect(() => {
    function typewriter() {
      setContents(' ');
      var iRow = Math.max(0, iIndex - iScrollAt);
      let newContents = '';
      while (iRow < iIndex) {
        newContents += aText[iRow++] + '<br />';
      }
      setContents(newContents + aText[iIndex].substring(0, iTextPos) + '_');
      if (iTextPos++ === iArrLength) {
        setTextPos(0);
        setIndex(iIndex + 1);
        if (iIndex !== aText.length - 1) {
          setArrLength(aText[iIndex + 1].length);
          setTimeout(typewriter, 1000);
        }
      } else {
        setTimeout(typewriter, iSpeed);
      }
    }

    typewriter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="typedtext" className="typewriter" dangerouslySetInnerHTML={{ __html: sContents }} />;
};

export default Typewriter;
