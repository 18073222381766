import React from "react";
import "./style.css";
import styled from "styled-components";
import bg from "../../images/bg.webp";
import logo from "../../images/brand/logo.svg";
import Typewriter from "./Typewriter";

const Background = styled.div`
  background-image: url(${bg});
  content: "Background";
  background-color: var(--ts-dark);
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
`;

const Brand = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ts-light);
  @media only screen and (max-width: 992px) {
    img {
      width: 50%;
    }
  }
`;
const Hero2 = () => {
  return (
    <div className="Hero2" data-testid="Hero2">
      <Background>
        <Brand>
          <img src={logo} alt="Teskill Logo" />
          {/* <p style={{fontSize:"var(--ts-text-heading-size)"}}>Committed to a Better Future</p> */}
          {/* <div class="typewriter">
            <p>Committed To a Better Future, Hello World </p>
          </div> */}
          <Typewriter text={"Committed To a Better Future"}/>
        </Brand>
      </Background>
    </div>
  );
};

export default Hero2;
