import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {Home} from './Pages/Home';
import {Contact} from './Pages/Contact';
import { Portfolio } from './Pages/Portfolio';
import { Error404 } from './Pages/Error404';


function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="*" element={<Navigate to="/404"/>} />
          <Route path="/404" element={<Error404/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
