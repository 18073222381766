import ServiceIcons from "../../../images/services/";

export default [
    {
        title: "Web Developing",
        icon: ServiceIcons.WebIcon,
        description: "Creating functional and aesthetically pleasing websites using programming languages."
    },
    {
        title: "App Developing",
        icon: ServiceIcons.AppIcon,
        description: "Building efficient and user-friendly mobile applications for various platforms."
    },
    {
        title: "Ui/Ux Designing",
        icon: ServiceIcons.UiUxIcon,
        description: "Designing intuitive and visually appealing interfaces for optimal user experience."
    },
    {
        title: "Content Writing",
        icon: ServiceIcons.WritingIcon,
        description: "Creating high-quality and engaging written content for websites and marketing."
    },
    {
        title: "SEO Services",
        icon: ServiceIcons.SEOIcon,
        description: "Optimizing websites to rank higher on search engine results pages."
    },
    {
        title: "Digital Marketing",
        icon: ServiceIcons.IdeaIcon,
        description: "Promoting products/services using digital channels to reach target audiences."
    }

]